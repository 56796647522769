import { baseURLPrefix } from 'app/constants'
import { constructQueryParamsFromObject } from 'utils/objects'
import { IFilterEmailVerifications } from 'app/containers/EmailVerifications/types'
import { IFilterEmailRejections } from 'app/containers/EmailRejections/types'
import { IFilterEmailInvalidations } from 'app/containers/EmailInvalidations/types'
import { IFilterDocumentInvalidations } from 'app/containers/DocumentInvalidations/types'
import { IFilterDocumentRejections } from 'app/containers/DocumentRejections/types'
import { IFilterPhoneInvalidations } from 'app/containers/PhoneInvalidations/types'
import { IFilterPhoneRejections } from 'app/containers/PhoneRejections/types'
import { IFilterPhoneVerifications } from 'app/containers/PhoneVerifications/types'
import { IFilterResubmissions } from 'app/containers/Resubmissions/types'
import { IFilterExternalWalletVerifications } from 'app/containers/ExternalWalletVerifications/types'

export const DocumentInvalidationsEndpoints = {
  documentInvalidationsList: (queryParams: IFilterDocumentInvalidations) =>
    `${baseURLPrefix}document/invalidations${constructQueryParamsFromObject(queryParams)}`,
}

export const DocumentRejectionsEndpoints = {
  documentRejectionsList: (queryParams: IFilterDocumentRejections) =>
    `${baseURLPrefix}document/rejections${constructQueryParamsFromObject(queryParams)}`,
}

export const EmailInvalidationsEndpoints = {
  emailInvalidationsList: (queryParams: IFilterEmailInvalidations) =>
    `${baseURLPrefix}email/invalidations${constructQueryParamsFromObject(queryParams)}`,
}

export const EmailVerificationsEndpoints = {
  emailVerificationsList: (queryParams: IFilterEmailVerifications) =>
    `${baseURLPrefix}email/verifications${constructQueryParamsFromObject(queryParams)}`,
}

export const EmailRejectionsEndpoints = {
  emailRejectionsList: (queryParams: IFilterEmailRejections) =>
    `${baseURLPrefix}email/rejections${constructQueryParamsFromObject(queryParams)}`,
}

export const ExternalWalletVerificationsEndpoints = {
  ExternalWalletVerificationsList: (
    queryParams: IFilterExternalWalletVerifications,
  ) =>
    `${baseURLPrefix}document/external-wallet-verifications${constructQueryParamsFromObject(queryParams)}`,
}

export const ExternalWalletInvalidationsEndpoints = {
  externalWalletInvalidationsList: (queryParams: IFilterEmailInvalidations) =>
    `${baseURLPrefix}document/invalidations${constructQueryParamsFromObject({ ...queryParams, vouchererName: 'ExternalWalletVerifier' })}`,
}

export const PhoneInvalidationsEndpoints = {
  phoneInvalidationsList: (queryParams: IFilterPhoneInvalidations) =>
    `${baseURLPrefix}phone/invalidations${constructQueryParamsFromObject(queryParams)}`,
}

export const PhoneRejectionsEndpoints = {
  phoneRejectionsList: (queryParams: IFilterPhoneRejections) =>
    `${baseURLPrefix}phone/rejections${constructQueryParamsFromObject(queryParams)}`,
}

export const PhoneVerificationsEndpoints = {
  phoneVerificationsList: (queryParams: IFilterPhoneVerifications) =>
    `${baseURLPrefix}phone/verifications${constructQueryParamsFromObject(queryParams)}`,
}

export const ResubmissionsEndpoints = {
  resubmissionList: (queryParams?: IFilterResubmissions) =>
    `${baseURLPrefix}document/verifications${constructQueryParamsFromObject(queryParams)}`,
}
