import { AppPages } from './constants'
import { Admins } from './containers/Admins'
import { DocumentInvalidations } from './containers/DocumentInvalidations'
import { DocumentRejections } from './containers/DocumentRejections'
import { EmailInvalidations } from './containers/EmailInvalidations'
import { EmailRejections } from './containers/EmailRejections'
import { EmailVerifications } from './containers/EmailVerifications'
import { MainPage } from './containers/MainPage'
import { Orders } from './containers/Orders'
import { Payments } from './containers/Payments'
import { PhoneInvalidations } from './containers/PhoneInvalidations'
import { PhoneRejections } from './containers/PhoneRejections'
import { PhoneVerifications } from './containers/PhoneVerifications'
import { Records } from './containers/Records'
import { ResubmissionItem } from './containers/ResubmissionItem'
import { Resubmissions } from './containers/Resubmissions'
import { Roles } from './containers/Roles'
import { SearchUser } from './containers/SearchUser'
import { Transmissions } from './containers/Transmissions'
import { Nfts } from './containers/Nfts'
import { Vouchers } from './containers/Vouchers'
import { IRoute } from './types'
import { ApiKeys } from './containers/ApiKeys'
import { Clients } from './containers/Clients'
import { ClientSourceKeys } from './containers/ClientSourceKeys'
import { Oauths } from './containers/Oauths'
import { Organizations } from './containers/Organizations'
import { Domains } from './containers/Domains'
import { AssetsManagement } from './containers/AssetsManagement'
import { Configurations } from './containers/Configurations'
import { BotOrders } from './containers/BotOrders'
import { ExternalWalletVerifications } from './containers/ExternalWalletVerifications'
import { ExternalWalletInvalidations } from './containers/ExternalWalletInvalidations'

export const routes: Array<IRoute> = [
  {
    key: AppPages.RootPage,
    title: 'Admins',
    path: AppPages.RootPage,
    enabled: false,
    component: Admins,
  },
  //Do NOT REMOVE THIS COMMENT
  // {
  //   key: AppPages.MainPage,
  //   title: 'Main Page',
  //   path: AppPages.MainPage,
  //   enabled: false,
  //   component: MainPage,
  // },
  {
    key: AppPages.Resubmissions,
    title: 'Resubmissions',
    path: AppPages.Resubmissions,
    enabled: true,
    component: Resubmissions,
  },
  {
    key: AppPages.ResubmissionItem,
    title: 'Resubmission Item',
    path: AppPages.ResubmissionItem,
    enabled: false,
    component: ResubmissionItem,
  },
  {
    key: AppPages.Roles,
    title: 'Roles',
    path: AppPages.Roles,
    enabled: false,
    component: Roles,
  },

  {
    key: AppPages.Admins,
    title: 'Admins',
    path: AppPages.Admins,
    enabled: false,
    component: Admins,
  },
  {
    key: AppPages.Orders,
    title: 'Orders',
    path: AppPages.Orders,
    enabled: false,
    component: Orders,
  },
  {
    key: AppPages.Payments,
    title: 'Payments',
    path: AppPages.Payments,
    enabled: false,
    component: Payments,
  },
  {
    key: AppPages.Vouchers,
    title: 'Vouchers',
    path: AppPages.Vouchers,
    enabled: false,
    component: Vouchers,
  },
  {
    key: AppPages.EmailVerifications,
    title: 'Email Verifications',
    path: AppPages.EmailVerifications,
    enabled: false,
    component: EmailVerifications,
  },
  {
    key: AppPages.PhoneVerifications,
    title: 'Email Verifications',
    path: AppPages.PhoneVerifications,
    enabled: false,
    component: PhoneVerifications,
  },
  {
    key: AppPages.ExternalWalletVerifications,
    title: 'External Wallet Verifications',
    path: AppPages.ExternalWalletVerifications,
    enabled: false,
    component: ExternalWalletVerifications,
  },
  {
    key: AppPages.ExternalWalletInvalidations,
    title: 'External Wallet Invalidations',
    path: AppPages.ExternalWalletInvalidations,
    enabled: false,
    component: ExternalWalletInvalidations,
  },
  {
    key: AppPages.PhoneInvalidations,
    title: 'Phone Invalidations',
    path: AppPages.PhoneInvalidations,
    enabled: false,
    component: PhoneInvalidations,
  },
  {
    key: AppPages.EmailInvalidations,
    title: 'Phone Invalidations',
    path: AppPages.EmailInvalidations,
    enabled: false,
    component: EmailInvalidations,
  },
  {
    key: AppPages.DocumentInvalidations,
    title: 'Document Invalidations',
    path: AppPages.DocumentInvalidations,
    enabled: false,
    component: DocumentInvalidations,
  },
  {
    key: AppPages.SearchUser,
    title: 'Search User',
    path: AppPages.SearchUser,
    enabled: false,
    component: SearchUser,
  },
  {
    key: AppPages.PhoneRejections,
    title: 'Phone Rejections',
    path: AppPages.PhoneRejections,
    enabled: false,
    component: PhoneRejections,
  },
  {
    key: AppPages.DocumentRejections,
    title: 'Document Rejection',
    path: AppPages.DocumentRejections,
    enabled: false,
    component: DocumentRejections,
  },
  {
    key: AppPages.EmailRejections,
    title: 'Email Rejections',
    path: AppPages.EmailRejections,
    enabled: false,
    component: EmailRejections,
  },
  {
    key: AppPages.Transmissions,
    title: 'Transmissions',
    path: AppPages.Transmissions,
    enabled: false,
    component: Transmissions,
  },
  {
    key: AppPages.Records,
    title: 'Records',
    path: AppPages.Records,
    enabled: false,
    component: Records,
  },
  {
    key: AppPages.ApiKeys,
    title: 'Api Keys',
    path: AppPages.ApiKeys,
    enabled: false,
    component: ApiKeys,
  },
  {
    key: AppPages.Clients,
    title: 'Clients',
    path: AppPages.Clients,
    enabled: false,
    component: Clients,
  },
  {
    key: AppPages.ClientSourceKeys,
    title: 'Client Source Keys',
    path: AppPages.ClientSourceKeys,
    enabled: false,
    component: ClientSourceKeys,
  },
  {
    key: AppPages.Oauths,
    title: 'Oauths',
    path: AppPages.Oauths,
    enabled: false,
    component: Oauths,
  },
  {
    key: AppPages.Organizations,
    title: 'Organizations',
    path: AppPages.Organizations,
    enabled: false,
    component: Organizations,
  },
  {
    key: AppPages.Domains,
    title: 'Domains',
    path: AppPages.Domains,
    enabled: false,
    component: Domains,
  },
  {
    key: AppPages.AssetsManagement,
    title: 'Assets Management',
    path: AppPages.AssetsManagement,
    enabled: false,
    component: AssetsManagement,
  },
  {
    key: AppPages.Configurations,
    title: 'Configurations',
    path: AppPages.Configurations,
    enabled: false,
    component: Configurations,
  },
  {
    key: AppPages.BotOrders,
    title: 'Bot Orders',
    path: AppPages.BotOrders,
    enabled: false,
    component: BotOrders,
  },
]
